import { css, StyleSheet } from "aphrodite";
import { COLORS } from "../../config/themes";

import ContentWrapper from "../ContentWrapper";
const HomepageSection = ({
  title,
  subtext,
  content,
  noRender,
  titleStyles,
  containerStyles,
}) => {
  const renderSection = () => {
    if (noRender) {
      return null;
    }

    return (
      <div className={css(styles.sectionContainer, containerStyles)}>
        <ContentWrapper>
          {subtext && <p className={css(styles.sectionSubtext)}>{subtext}</p>}
          {title && (
            <h2 className={css(styles.sectionTitle, titleStyles)}>{title}</h2>
          )}
          {content}
        </ContentWrapper>
      </div>
    );
  };
  return <>{renderSection()}</>;
};

const styles = StyleSheet.create({
  sectionContainer: {
    marginTop: 150,
    marginBottom: 150,
    display: "flex",
    position: "relative",
    justifyContent: "center",

    "@media only screen and (max-width: 1023px)": {
      paddingLeft: 20,
      paddingRight: 20,
      marginTop: 75,
      marginBottom: 75,
    },
  },
  sectionTitle: {
    fontSize: 42,
    fontWeight: 600,
    lineHeight: "54px",
    marginBottom: 60,
    width: "50%",

    "@media only screen and (max-width: 1023px)": {
      fontSize: 38,
      lineHeight: "48px",
      width: "100%",
    },
    "@media only screen and (max-width: 767px)": {
      fontSize: 28,
      lineHeight: "34px",
    },
    "@media only screen and (max-width: 374px)": {
      fontSize: 24,
    },
  },
  sectionSubtext: {
    fontWeight: 500,
    fontSize: 16,
    marginBottom: 30,
    width: "50%",
    textTransform: "uppercase",
    color: COLORS.BLACK(0.8),

    "@media only screen and (max-width: 1023px)": {
      fontSize: 16,
      width: "70%",
    },
    "@media only screen and (max-width: 767px)": {
      width: "100%",
    },
  },
});
export default HomepageSection;
