import { StyleSheet, css } from "aphrodite";

const ContentWrapper = (props) => {
  return (
    <div className={css(styles.contentWrapper, props.className)}>
      {props.children}
    </div>
  );
};

const styles = StyleSheet.create({
  contentWrapper: {
    maxWidth: 1280,
    zIndex: 2,
    width: "100%",

    "@media only screen and (max-width: 767px)": {
      width: "100%",
    },

    "@media only screen and (max-width: 1300px)": {
      width: "95%",
    },

    "@media only screen and (max-width: 425px)": {
      width: "100%",
    },
  },
});

export default ContentWrapper;
