import { useState, useEffect, useRef } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { StyleSheet, css } from "aphrodite";
import { useRouter } from "next/router";

import Button from "../Form/Button";
import LocationSearch from "../LocationSearch";

import { notifyBugSnag } from "../../utils/ErrorChecking";
import { formatRealEstateAgentsLocationURL } from "../../utils/AgentUtils";

import { COLORS } from "~/config/themes";
import { queryParams as getQueryParams } from "../../utils/Fetch/Analytics";

const InputComponentCTA = ({
  inputPlaceholder,
  locationData,
  inputGroupStyle,
  inputContainerStyle,
  type,
  options,
  ctaButtonText = "Find an Agent",
  searchButtonStyle = styles.searchButton,
  useSearchButton = false,
}) => {
  const router = useRouter();

  const [selectedLocation, setSelectedLocation] = useState(null);

  const [addressComponents, setAddressComponents] = useState(null);

  const [buttonClicked, setButtonClicked] = useState(false);

  const [errorMsg, setErrorMsg] = useState(null);

  const buttonTimeout = useRef(null);

  useEffect(() => {
    if (locationData) {
      setSelectedLocation(
        locationData && locationData.city
          ? locationData.city + ", " + locationData.state
          : null
      );
    }
  }, [locationData]);

  useEffect(() => {
    return () => {
      setErrorMsg(null);
      clearTimeout(buttonTimeout.current);
    };
  }, []);

  const onInputChange = (value) => {
    setSelectedLocation(value);
  };

  const topAgentsSubmitLocation = () => {
    const path = router.query.path;
    if (path.includes("top-agents")) {
      router.push(
        `/top-agents/${state.toLowerCase()}/${city
          .replace(" ", "-")
          .toLowerCase()}?lat=${lat}&lon=${lon}&lead_type=${leadTypeInner}&max_price=${maxPrice}&min_price=${minPrice}`
      );
    }
  };

  const submitLocation = (e) => {
    clearTimeout(buttonTimeout.current);
    if (addressComponents && addressComponents.city) {
      setButtonClicked(true);
      buttonTimeout.current = setTimeout(() => {
        let queryParams = getQueryParams();
        const { path, url } = formatRealEstateAgentsLocationURL({
          city: addressComponents.city,
          state: addressComponents.state,
        });
        router.push(
          path + `${queryParams ? `${queryParams}` : ""}`,
          url + `${queryParams ? `${queryParams}` : ""}`,
          { shallow: true }
        );
        setErrorMsg(null);
      }, 400);
    } else {
      setErrorMsg("Select a city or address to continue.");

      notifyBugSnag({
        error: "Incomplete address, a city is required",
        metadata: [
          {
            label: "address_value",
            fields: {
              selectedLocation: selectedLocation,
              addressComponents: addressComponents,
            },
          },
          {
            label: "route",
            fields: {
              url: window.location,
            },
          },
        ],
      });
    }
  };

  const onLocationSelect = ({ addressParams, address }) => {
    setSelectedLocation(address);

    setButtonClicked(true);
    let queryParams = getQueryParams();
    const { path, url } = formatRealEstateAgentsLocationURL({
      city: addressParams.city,
      state: addressParams.state,
    });

    router.push(
      path + `${queryParams ? `${queryParams}` : ""}`,
      url + `${queryParams ? `${queryParams}` : ""}`,
      { shallow: true }
    );
  };

  return (
    <div className={css(styles.sellCTA)}>
      <span className={css(styles.errorMsg)}>{errorMsg && errorMsg}</span>
      <LocationSearch
        searchContainerStyles={[styles.inputContainer, inputContainerStyle]}
        inputStyles={[styles.input, inputGroupStyle]}
        inputPlaceholder={inputPlaceholder}
        icon={["fal", "map-marker-alt"]}
        iconSize={"lg"}
        type={type}
        iconColor={COLORS.BLACK(0.6)}
        searchButton={
          useSearchButton ? (
            <button
              className={css(styles.button, searchButtonStyle)}
              aria-label={`Search button`}
            >
              <FontAwesomeIcon
                icon={["fal", "search"]}
                color={"#fff"}
                size="md"
                className={css(styles.searchIcon)}
              />
            </button>
          ) : (
            <Button
              newButton={true}
              className={[styles.searchButton, searchButtonStyle]}
              text={
                buttonClicked ? (
                  <FontAwesomeIcon
                    className={"fa-spin"}
                    icon={["far", "spinner-third"]}
                    size={"lg"}
                  />
                ) : (
                  `${ctaButtonText}`
                )
              }
              onClick={submitLocation}
            />
          )
        }
      />

      {!useSearchButton && (
        <Button
          newButton={true}
          className={[searchButtonStyle, styles.searchButtonMobile]}
          text={
            buttonClicked ? (
              <FontAwesomeIcon
                className={"fa-spin"}
                icon={["far", "spinner-third"]}
                size={"lg"}
              />
            ) : (
              `${ctaButtonText}`
            )
          }
          onClick={submitLocation}
        />
      )}
    </div>
  );
};

const styles = StyleSheet.create({
  sellCTA: {
    width: "100%",
  },
  errorMsg: {
    color: "#ff0033",
  },
  errorBorder: {
    border: `2px solid ${COLORS.THERMO_RED(0.5)}`,
  },
  searchInputGroup: {
    height: 70,
  },
  inputContainer: {
    width: "100%",
    borderRadius: 7,
    height: 70,
    padding: 0,

    "@media only screen and (max-width: 767px)": {
      height: "unset",
      background: "#fff",
      height: 50,
    },

    "@media only screen and (min-width: 1024px)": {
      width: "100%",
    },
  },
  input: {
    width: "calc(100% - 50px)",

    "::-webkit-input-placeholder": {
      color: COLORS.BLACK(0.6),
    },

    "@media only screen and (max-width: 767px)": {
      fontSize: 12,
    },
  },
  searchButton: {
    height: "100%",
    width: "100%",
    maxWidth: 200,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    background: COLORS.PRIMARY_ACTION(),
    fontWeight: 700,
    fontSize: 20,
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 0,

    "@media only screen and (max-width: 1300px)": {
      display: "none",
    },
  },
  searchButtonMobile: {
    display: "none",

    "@media only screen and (max-width: 1300px)": {
      display: "inline-block",
      width: "100%",
      height: 50,
      maxWidth: "unset",
      marginTop: 10,
      borderRadius: 5,
    },
  },
  inputWrapper: {
    maxWidth: 750,
    width: "100%",
  },
  iconStyles: {
    marginLeft: 10,
    marginRight: 8,
    fontSize: 12,

    "@media only screen and (min-width: 768px)": {
      marginLeft: 20,
      marginRight: 10,
    },
  },
  searchIcon: {
    background: "transparent",
  },
});

export default InputComponentCTA;
