export const blog = {
  pageLimit: 8,
  featuredLimit: 3,
  baseUrl: "/blog/",
  guidesUrl: "/real-estate-guides/",
  url: (input) => {
    const url = new URL(
      input,
      "https://rea-dot-com.ghost.io/resource/ghost/api/content/"
    );
    url.searchParams.set("key", "936c79bc5b17c076aa45f55fe8");
    return url;
  },
};

export const NON_PRIME_FILTERS = {
  exclude: {
    tags: ["webinar"],
  },
};

export const exclude_filter = () => {
  const exclude_tag_filters = NON_PRIME_FILTERS.exclude.tags;
  let filters = "";
  if (exclude_tag_filters) {
    for (let i = 0; i < exclude_tag_filters.length; i++) {
      if (i === 0) {
        filters = `tag:-${exclude_tag_filters[i]}`;
      } else {
        filters += `+tag:-${exclude_tag_filters[i]}`;
      }
    }
  }
  return filters;
};
